<template>
  <div class="new_password-wrapper">
    <div class="new_password-header">Новый пароль</div>
    <div class="new_password-sub-header">
      <div>
        Введите новый пароль от 5 до 10 символом, содержащие заглавны и цифровые
        значения
      </div>
      <div>
        <ul>
          <li class="red">Пароль не должен содержать только символы</li>
          <li class="red">Пароль должен содержать заглавные буквы</li>
          <li class="green">Отличный</li>
        </ul>
      </div>
    </div>
    <form class="new_password-form" @submit.prevent="passwordRecovery">
      <a-form-item validate-status="error">
        <a-input-password
          placeholder="Пароль"
          v-model="password"
          size="large"
          class="new_password-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input-password>
      </a-form-item>

      <a-form-item validate-status="error">
        <a-input-password
          placeholder="Повторите пароль"
          v-model="c_password"
          size="large"
          class="new_password-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ fontSize: '16px', color: '#08c' }"
          /> </a-input-password
      ></a-form-item>

      <div class="new_password-submit-button-wrapper">
        <button class="new_password-submit-button" type="submit">
          Отправить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";

// import { login } from "@/helpers/authHelper";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      password: "",
      c_password: "",
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
    };
  },
  methods: {
    passwordRecovery: function () {
      this.$emit("nextStep");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.new_password-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.new_password-sub-header {
  padding-top: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
  content: "";
}

.new_password-form {
  padding-top: 15px;
  width: 100%;
  text-align: left;
}

.new_password-input-wrapper {
  padding: 0px 0px;
}

.ant-form-item {
  margin: 0px;
  padding-top: 10px;
}

.new_password-submit-button-wrapper {
  padding-top: 25px;
}

.new_password-submit-button {
  width: 100%;
  background-color: var(--use-color-primary-600);
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 2px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0px;
}
ul > li {
  padding-left: 20px;
  position: relative;
  margin: 10px;
}
ul > li:before {
  position: absolute;
  top: 0;
  left: 0;
}

.red {
  color: red;
}

.red:before {
  content: "✗";
}

.green {
  color: green;
}

.green:before {
  content: "✔";
}

@media (max-width: 507px) {
  .new_password-header {
    font-size: 18px;
    padding-top: 28px;
  }
}
</style>
