<template>
  <div class="password_recovery-wrapper">
    <div class="password_recovery-header">Восстановление пароля</div>
    <form class="password_recovery-form" @submit.prevent="passwordRecovery">
      <a-input
        placeholder="Номер телефона"
        v-model="phone"
        size="large"
        class="password_recovery-input-wrapper"
      >
        <a-icon
          slot="prefix"
          type="phone"
          :style="{ fontSize: '16px', color: '#08c' }"
        />
      </a-input>

      <div class="password_recovery-submit-button-wrapper">
        <button class="password_recovery-submit-button" type="submit">
          Отправить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";

// import { login } from "@/helpers/authHelper";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      phone: "",
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
    };
  },
  methods: {
    passwordRecovery: function () {
      this.$emit("nextStep");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.password_recovery-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.password_recovery-sub-header {
  padding: 15px 0px 80px 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
  content: "";
}

.password_recovery-form {
  padding-top: 100px;
  width: 100%;
  text-align: left;
}

.password_recovery-input-wrapper {
  padding: 12px 0px;
}

.password_recovery-submit-button-wrapper {
  padding-top: 16px;
}

.password_recovery-submit-button {
  width: 100%;
  background-color: var(--use-color-primary-600);
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 2px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 507px) {
  .password_recovery-header {
    font-size: 18px;
    padding-top: 28px;
  }
}
</style>
